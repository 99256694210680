/* Глобальные стили */
@import "./styles/colors.styl"
@import "./styles/constants.styl"

.text-bold 
  font-weight: 600

input, textarea
  &.ng-touched.ng-invalid
    border-color: red

  &.ng-touched.ng-valid
    border-color: green

.fw-500
  font-weight: 500

.section
  padding: 20px 0
  min-height: calc(100vh - 53px - 45px)

.title
  font-size: 24px

.invalid-feedback.show
  display: block

.fancybox__container .carousel__button.is-close
  right: -36px


@media (max-width: 360px) 
  body
    font-size: 14px

  .container
    padding-left: 10px
    padding-right: 10px

  .title
    font-size: 22px

@media (max-width: bootstrap.md.max)
  .hidden-mobile
    display: none !important

@media (max-width: bootstrap.md.min)
  .hidden-pk
    display: none !important
